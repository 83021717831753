import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Clock, Check, X } from "react-feather";
import BuiltWithWorkplay from "../components/BuiltWithWorkplay";
import { RootState } from "../store";
import useFetchQuizDetails from "../hooks/useFetchQuizDetails";
import LoadingSpinner from "../components/LoadingSpinner";
import LogoComponent from "../components/LogoComponent";
import { useLogoSize } from "../hooks/useLogoSize";
import useGameStyles from "../hooks/useGameStyles";
import LinkActiveOrExpiredPage from "./LinkActiveOrExpiredPage";

type OptionType = string;

const QuizGamePage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showTimerEndPopup, setShowTimerEndPopup] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    const storedIndex = sessionStorage.getItem("quizCurrentQuestionIndex");
    return storedIndex ? JSON.parse(storedIndex) : 0;
  });
  const [score, setScore] = useState(() => {
    const storedScore = sessionStorage.getItem("quizScore");
    return storedScore ? JSON.parse(storedScore) : 0;
  });

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [isLastQuestionAnswered, setIsLastQuestionAnswered] = useState(false);

  const getQueryParams = (query: string) => {
    return query
      ? JSON.parse(
          '{"' +
            decodeURI(query)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}',
        )
      : {};
  };

  const queryParams = getQueryParams(location.search.substring(1));
  const { campaignId } = queryParams;

  const {
    loading,
    status,
    message,
    quizDetails: fetchedQuizDetails,
  } = useFetchQuizDetails(campaignId);
  const quizDetails =
    useSelector((state: RootState) => state.game.quizDetails) ||
    fetchedQuizDetails;
  const timerInSeconds = quizDetails.timerInSeconds;
  const [remainingTime, setRemainingTime] = useState(() => {
    const storedTime = sessionStorage.getItem("quizRemainingTime");
    return storedTime ? JSON.parse(storedTime) : timerInSeconds;
  });
  const { backgroundStyle, textStyles } = useGameStyles(quizDetails);

  const logoUrl =
    quizDetails && quizDetails.logo
      ? `${process.env.REACT_APP_S3_URL}/quiz/logo/${quizDetails.logo.image}`
      : null;
  const logoSize = useLogoSize(logoUrl);

  useEffect(() => {
    setRemainingTime(timerInSeconds);
  }, [timerInSeconds]);

  useEffect(() => {
    const storedCurrentQuestionIndex = sessionStorage.getItem(
      "currentQuestionIndex",
    );
    const storedScore = sessionStorage.getItem("quizScore");
    const storedRemainingTime = sessionStorage.getItem("quizRemainingTime");

    if (storedCurrentQuestionIndex !== null) {
      setCurrentQuestionIndex(JSON.parse(storedCurrentQuestionIndex));
    }
    if (storedScore !== null) {
      setScore(JSON.parse(storedScore));
    }

    let initialRemainingTime = timerInSeconds;
    if (storedRemainingTime !== null) {
      initialRemainingTime = JSON.parse(storedRemainingTime);
      setRemainingTime(initialRemainingTime);
    } else {
      setRemainingTime(timerInSeconds);
    }
  }, [timerInSeconds]);

  useEffect(() => {
    if (!loading && quizDetails) {
      const storedRemainingTime = sessionStorage.getItem("quizRemainingTime");
      let currentRemainingTime = storedRemainingTime
        ? JSON.parse(storedRemainingTime)
        : timerInSeconds;

      setRemainingTime(currentRemainingTime);

      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      timerRef.current = setInterval(() => {
        setRemainingTime((prevTime: number) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current!);
            setShowTimerEndPopup(true);
            sessionStorage.setItem("quizRemainingTime", JSON.stringify(0));
            return 0;
          } else {
            const newTime = prevTime - 1;
            sessionStorage.setItem(
              "quizRemainingTime",
              JSON.stringify(newTime),
            );
            return newTime;
          }
        });
      }, 1000);

      return () => clearInterval(timerRef.current!);
    }
  }, [loading, quizDetails, timerInSeconds, currentQuestionIndex]);

  useEffect(() => {
    if (currentQuestionIndex < quizDetails.questions.length) {
      const currentQuestion = quizDetails.questions[currentQuestionIndex];
      const correctOption = currentQuestion?.options.find(
        (option: OptionType) => option === currentQuestion.correctOptionValue,
      );
      setSelectedOptionId(correctOption ? correctOption : null);
    } else {
      console.log("No more questions available.");
    }
  }, [quizDetails.questions, currentQuestionIndex]);

  useEffect(() => {
    sessionStorage.setItem(
      "quizCurrentQuestionIndex",
      JSON.stringify(currentQuestionIndex),
    );
    sessionStorage.setItem("quizScore", JSON.stringify(score));
    sessionStorage.setItem("quizRemainingTime", JSON.stringify(remainingTime));
  }, [currentQuestionIndex, score, remainingTime]);

  const handleOptionClick = (option: OptionType) => {
    setShowResult(true);
    setSelectedOptionId(option);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    const timeTakenForQuestion = quizDetails.timerInSeconds - remainingTime;
    const totalTimeTaken = sessionStorage.getItem("quizTotalTimeTaken")
      ? JSON.parse(sessionStorage.getItem("quizTotalTimeTaken")!)
      : 0;
    const newTotalTimeTaken = totalTimeTaken + timeTakenForQuestion;
    sessionStorage.setItem(
      "quizTotalTimeTaken",
      JSON.stringify(newTotalTimeTaken),
    );

    if (
      option === quizDetails.questions[currentQuestionIndex]?.correctOptionValue
    ) {
      setScore((prevScore: number) => {
        const newScore = prevScore + 1;
        sessionStorage.setItem("quizScore", JSON.stringify(newScore));
        return newScore;
      });
    }

    setTimeout(() => {
      if (currentQuestionIndex + 1 < quizDetails.questions.length) {
        handleNextQuestion();
      } else {
        setIsLastQuestionAnswered(true);
        setTimeout(() => {
          navigate(`/result?campaignId=${campaignId}`, { replace: true });
        }, 500);
      }
    }, 1200);
  };

  const handleNextQuestion = () => {
    setShowResult(false);
    setSelectedOptionId(null);

    if (currentQuestionIndex + 1 < quizDetails.questions.length) {
      setCurrentQuestionIndex((prevIndex: number) => {
        const newIndex = prevIndex + 1;
        sessionStorage.setItem(
          "quizCurrentQuestionIndex",
          JSON.stringify(newIndex),
        );
        return newIndex;
      });
    } else {
      navigate(`/result?campaignId=${campaignId}`, { replace: true });
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    let currentRemainingTime = quizDetails.timerInSeconds;
    setRemainingTime(currentRemainingTime);
    sessionStorage.setItem(
      "quizRemainingTime",
      JSON.stringify(currentRemainingTime),
    );

    timerRef.current = setInterval(() => {
      currentRemainingTime -= 1;
      if (currentRemainingTime <= 0) {
        setShowTimerEndPopup(true);
        clearInterval(timerRef.current!);
        currentRemainingTime = 0;
      }
      setRemainingTime(currentRemainingTime);
      sessionStorage.setItem(
        "quizRemainingTime",
        JSON.stringify(currentRemainingTime),
      );
    }, 1000);
  };

  const handleOkClick = () => {
    setShowTimerEndPopup(false);
    navigate(`/play?campaignId=${campaignId}`, { replace: true });
    sessionStorage.removeItem("quizScore");
    sessionStorage.removeItem("quizRemainingTime");
    sessionStorage.removeItem("quizCurrentQuestionIndex");
    sessionStorage.removeItem("quizUserName");
    sessionStorage.removeItem("quizUserEmail");
    sessionStorage.removeItem("quizTotalTimeTaken");
  };

  if (status === 200 && message === "Event yet to start") {
    return <LinkActiveOrExpiredPage message="This link is not active yet" />;
  }

  if (status === 400 && message === "Event link expired") {
    return <LinkActiveOrExpiredPage message="This link is no longer active" />;
  }

  if (loading || !quizDetails) {
    return (
      <LoadingSpinner
        backgroundColor={quizDetails?.background?.secondaryBColor || "#ffffff"}
        secondaryBackgroundColor={
          quizDetails?.background?.primaryBColor || "#ffffff"
        }
        isImageBackground={Boolean(quizDetails?.background?.image)}
      />
    );
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white">
      <div
        className="relative flex h-full w-full flex-col items-center justify-center p-4"
        style={backgroundStyle}
      >
        <div
          className="flex min-w-[300px] items-center justify-center rounded-xl px-6 py-10 lg:min-w-[400px] lg:p-10"
          style={{
            backgroundColor: quizDetails?.background?.image
              ? `rgba(${parseInt(quizDetails.background.secondaryBColor?.slice(1, 3) || "0", 16)}, ${parseInt(
                  quizDetails.background.secondaryBColor?.slice(3, 5) || "0",
                  16,
                )}, ${parseInt(quizDetails.background.secondaryBColor?.slice(5, 7) || "0", 16)}, 0.7)`
              : "transparent",
            fontFamily: textStyles.fontFamily,
            outlineColor: quizDetails?.background?.image
              ? quizDetails.background.secondaryBColor
              : quizDetails?.background?.primaryBColor,
          }}
        >
          <div className="w-full">
            {quizDetails && quizDetails.logo && (
              <LogoComponent
                specification={{
                  logo: { previewUrl: logoUrl },
                  websiteUrl: quizDetails.logo.websiteUrl,
                }}
                logoSize={logoSize}
              />
            )}
            <div className="flex w-full justify-center space-x-4">
              <div
                className="flex items-center space-x-2 rounded-full px-4 py-2"
                style={
                  quizDetails?.background?.image
                    ? {
                        backgroundColor: `rgba(${parseInt(quizDetails.background.textColor.slice(1, 3), 16)}, ${parseInt(quizDetails.background.textColor.slice(3, 5), 16)}, ${parseInt(quizDetails.background.textColor.slice(5, 7), 16)}, 0.8)`,
                        color: quizDetails.background.secondaryBColor,
                        fontSize: textStyles.scoreSize,
                        fontFamily: textStyles.fontFamily,
                      }
                    : {
                        backgroundColor: "black",
                        color: "white",
                        fontSize: textStyles.scoreSize,
                        fontFamily: textStyles.fontFamily,
                      }
                }
              >
                <span>Score: {score}</span>
              </div>
              <div
                className="flex items-center space-x-2 rounded-full px-4 py-2"
                style={
                  quizDetails?.background?.image
                    ? {
                        backgroundColor: `rgba(${parseInt(quizDetails.background.textColor.slice(1, 3), 16)}, ${parseInt(quizDetails.background.textColor.slice(3, 5), 16)}, ${parseInt(quizDetails.background.textColor.slice(5, 7), 16)}, 0.8)`,
                        color: quizDetails.background.secondaryBColor,
                        fontSize: textStyles.timerSize,
                        fontFamily: textStyles.fontFamily,
                      }
                    : {
                        backgroundColor: "black",
                        color: "white",
                        fontSize: textStyles.timerSize,
                        fontFamily: textStyles.fontFamily,
                      }
                }
              >
                <Clock className="h-4 w-4" />
                <span>
                  00:{remainingTime < 10 ? `0${remainingTime}` : remainingTime}
                </span>
              </div>
            </div>
            <div className="my-1 flex w-full justify-center">
              <div className="my-4 flex w-full justify-center">
                <div className="flex w-full">
                  {quizDetails?.questions.map((_: any, index: number) => (
                    <div
                      key={index}
                      className={`mx-1 h-[3px] flex-1 rounded-full ${
                        index < currentQuestionIndex ||
                        (index === currentQuestionIndex &&
                          isLastQuestionAnswered)
                          ? "bg-white"
                          : "bg-white opacity-40"
                      }`}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full text-center">
              <h2
                className="m-auto break-words text-xl font-semibold lg:w-[550px]"
                style={{
                  fontSize: textStyles.questionSize,
                  fontFamily: textStyles.fontFamily,
                }}
              >
                {currentQuestionIndex + 1}.{" "}
                {quizDetails.questions[currentQuestionIndex]?.question}
              </h2>
              <div className="mt-4 flex flex-col items-center space-y-3 font-medium">
                {quizDetails.questions[currentQuestionIndex]?.options.map(
                  (option: OptionType, index: number) => {
                    const isCorrect =
                      option ===
                      quizDetails.questions[currentQuestionIndex]
                        ?.correctOptionValue;
                    const isSelected = option === selectedOptionId;

                    return (
                      <button
                        key={index}
                        className={`flex h-11 w-full items-center break-words rounded-full px-4 text-left ${
                          showResult && isSelected
                            ? isCorrect
                              ? "bg-green-500 text-white"
                              : "bg-red-500 text-white"
                            : showResult && isCorrect
                              ? "bg-green-500 text-white"
                              : "bg-yellow-500 text-black"
                        } ${quizDetails.background.image ? "border" : ""}`}
                        style={
                          quizDetails.background.image
                            ? {
                                borderColor: showResult
                                  ? isCorrect
                                    ? "green"
                                    : isSelected
                                      ? "red"
                                      : quizDetails.background.textColor
                                  : quizDetails.background.textColor,
                                backgroundColor: showResult
                                  ? isCorrect || isSelected
                                    ? ""
                                    : quizDetails.background.primaryBColor
                                  : quizDetails.background.primaryBColor,
                                color:
                                  showResult && (isCorrect || isSelected)
                                    ? "white"
                                    : quizDetails.background.textColor,
                                fontFamily: textStyles.fontFamily,
                                fontSize: textStyles.optionSize,
                              }
                            : {
                                fontFamily: textStyles.fontFamily,
                                fontSize: textStyles.optionSize,
                                backgroundColor: showResult
                                  ? isCorrect || isSelected
                                    ? ""
                                    : "#facc15"
                                  : "#facc15",
                                color:
                                  showResult && (isCorrect || isSelected)
                                    ? "white"
                                    : "black",
                              }
                        }
                        onClick={() => handleOptionClick(option)}
                        disabled={showResult}
                      >
                        {showResult &&
                          (isSelected || isCorrect) &&
                          (isCorrect ? (
                            <Check className="mr-2 h-6 w-6 rounded-full bg-white p-1 text-green-500" />
                          ) : (
                            isSelected && (
                              <X className="mr-2 h-6 w-6 rounded-full bg-white p-1 text-red-500" />
                            )
                          ))}
                        <span className="mr-2 font-bold">
                          {String.fromCharCode(65 + index)}.
                        </span>
                        {option}
                      </button>
                    );
                  },
                )}
              </div>
            </div>
          </div>
        </div>
        <BuiltWithWorkplay />
        {showTimerEndPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div
              className="h-fit w-[85%] rounded-xl bg-white p-6 text-center xsm:w-72"
              style={{
                fontFamily: textStyles.fontFamily,
                backgroundColor: quizDetails.background.image
                  ? quizDetails.background.secondaryBColor
                  : quizDetails.background.backgroundColor,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <h2 className="mb-2 text-xl font-bold">Time's up!</h2>
              <p style={{ fontSize: textStyles.instructionSize }}>
                You couldn't complete the quiz in time. Better luck next time!
              </p>
              <button
                className="mt-5 w-24 rounded-full py-2 font-semibold uppercase shadow-sm"
                style={{
                  fontFamily: textStyles.fontFamily,
                  fontSize: textStyles.buttonSize,
                  backgroundColor: quizDetails.background.image
                    ? quizDetails.background.primaryBColor
                    : "#facc15",
                }}
                onClick={handleOkClick}
              >
                ok
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizGamePage;
