import { useEffect, useState } from "react";
import { getQuizDetails } from "../api/getQuizDetailsAPI";
import axios from "axios";

const useFetchQuizDetails = (campaignId: string | null) => {
  const [quizDetails, setQuizDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<number | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  useEffect(() => {
    if (campaignId) {
      const fetchQuizDetails = async () => {
        try {
          const response = await getQuizDetails(campaignId);
          if (response.status === 200) {
            // Check if the event has started
            if (response.data.message === "Event yet to start") {
              setStatus(response.status);
              setMessage(response.data.message);
              return {
                status: response.status,
                message: response.data.message,
              };
            }

            setQuizDetails(response.data.data);

            if (response.data.data.background?.image) {
              const filename = response.data.data.background.image;
              const imageUrl = `${process.env.REACT_APP_S3_URL}/quiz/background/${filename}`;
              const res = await fetch(imageUrl);
              const blob = await res.blob();
              const file = new File([blob], filename, { type: blob.type });
              const previewUrl = URL.createObjectURL(file);
              setQuizDetails((prevDetails: any) => ({
                ...prevDetails,
                background: {
                  ...prevDetails.background,
                  previewUrl,
                },
              }));
            }

            return {
              status: response.status,
              message: response.data.message,
            };
          }

          // If we reach here, something went wrong with the response status
          console.error("Unexpected status:", response.status);
          return {
            status: response.status,
            message: "Unexpected error occurred.",
          };
        } catch (error) {
          // Check if error response exists
          if (axios.isAxiosError(error) && error.response) {
            console.error("Error fetching data:", error.response.data);
            const { status, data } = error.response;
            if (status === 400 && data.message === "Event link expired") {
              setStatus(error.response?.status || 500);
              setMessage(
                error.response?.data?.message || "Error fetching data",
              );

              return { status, message: data.message }; // Return the specific error message
            }
            return { status, message: "Unexpected error occurred." };
          } else {
            console.error("Error fetching data:", error);
            return { status: 500, message: "Error fetching data" }; // Fallback status and message
          }
        } finally {
          setLoading(false);
        }
      };

      fetchQuizDetails();
    }
  }, [campaignId]);

  return { quizDetails, loading, status, message };
};

export default useFetchQuizDetails;
