import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  setDescription,
  setDisplayInstructions,
  setInstructions,
  setLogo,
  setLogoFilename,
  setQuestions,
  setQuizTimer,
  setTitle,
  setWebsiteUrl,
} from "../store/slices/specificationSlice";
import {
  setBackgroundType,
  setColorPaletteTextColor,
  setFontFamily,
  setSelectedBackgroundColor,
  setUploadImage,
  setUploadImageTextColor,
  setUploadPrimaryBackgroundColor,
  setUploadSecondaryBackgroundColor,
} from "../store/slices/designSlice";
import {
  setDisplayLeaderboard,
  setNumberOfWinners,
} from "../store/slices/InteractivitySlice";
import { getQuizDetails } from "../api/getQuizDetailsAPI";

const useFetchAndSetQuizDetails = (
  gameId: string | null,
  ccid: string | null,
  token: string | null,
  setLoading: (loading: boolean) => void,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const storedCcid = sessionStorage.getItem("ccid");

    const fetchQuizDetails = async () => {
      if (gameId && ccid && token && (!storedCcid || storedCcid !== ccid)) {
        try {
          const response = await getQuizDetails(gameId);
          const data = response.data.data;

          const title = data.title || "Quiz Time";
          const description =
            data.description ||
            "Get ready to play! Click 'Play' to start your quiz adventure.";
          const timerInSeconds = data.timerInSeconds || 0;
          const displayInstructions = data.displayInstructions || false;
          const instructions = Array.isArray(data.instructions)
            ? data.instructions.map((instruction: string, index: number) => ({
                id: index + 1,
                text: instruction,
              }))
            : [];
          const logoFilename = data.logo?.image ? `${data.logo.image}` : null;
          const websiteUrl = data.logo?.websiteUrl || "";
          const questions = Array.isArray(data.questions)
            ? data.questions.map((q: any, index: number) => ({
                id: index + 1,
                text: q.question || "This is the question",
                options: Array.isArray(q.options)
                  ? q.options.map((o: any, idx: number) => ({
                      id: idx + 1,
                      text: o || `Option ${idx + 1}`,
                    }))
                  : [],
                correctAnswer: q.correctOptionValue || "",
              }))
            : [];
          const backgroundFilename = data.background?.image || "";

          dispatch(setTitle(title));
          dispatch(setDescription(description));
          dispatch(setQuizTimer(timerInSeconds));
          dispatch(setDisplayInstructions(displayInstructions));
          dispatch(setInstructions(instructions));
          if (logoFilename || websiteUrl) {
            dispatch(
              setLogo({
                file: null,
                previewUrl: logoFilename
                  ? `${process.env.REACT_APP_S3_URL}/quiz/logo/${logoFilename}`
                  : null,
              }),
            );
            dispatch(setLogoFilename(logoFilename));
            dispatch(setWebsiteUrl(websiteUrl));
          }
          dispatch(setQuestions(questions));

          const backgroundType = data.background?.image
            ? "uploadImage"
            : "colorPalette";
          dispatch(setBackgroundType(backgroundType));

          if (backgroundType === "uploadImage") {
            dispatch(
              setUploadImage({
                file: null,
                previewUrl: `${process.env.REACT_APP_S3_URL}/quiz/background/${backgroundFilename}`,
                filename: backgroundFilename,
              }),
            );
            dispatch(setUploadImageTextColor(data.background.textColor));
            dispatch(
              setUploadPrimaryBackgroundColor(data.background.primaryBColor),
            );
            dispatch(
              setUploadSecondaryBackgroundColor(
                data.background.secondaryBColor,
              ),
            );
          } else {
            dispatch(
              setSelectedBackgroundColor(data.background.backgroundColor),
            );
            dispatch(setColorPaletteTextColor(data.background.textColor));
          }

          dispatch(setFontFamily(data.fontFamily || "Trebuchet MS"));
          dispatch(setDisplayLeaderboard(data.leaderboard || false));
          dispatch(setNumberOfWinners(data.leaderboardLimit || 10));

          sessionStorage.setItem("ccid", ccid);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching quiz details:", error);
          setLoading(false);
        }
      }
    };

    fetchQuizDetails();
  }, [gameId, ccid, token, dispatch, setLoading]);
};

export default useFetchAndSetQuizDetails;
