import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import specificationReducer from "./slices/specificationSlice";
import interactivityReducer from "./slices/InteractivitySlice";
import designReducer from "./slices/designSlice";
import gameReducer from "./slices/gameSlice";

const rootReducer = combineReducers({
  specification: specificationReducer,
  interactivity: interactivityReducer,
  design: designReducer,
  game: gameReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["design/setUploadImage"],
        ignoredPaths: ["design.uploadImage.file"],
        ignoredActionPaths: ["payload.file"],
      },
    }),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export { store, persistor };
